import React from 'react';
import Navigation from "../Home Components/Navigation";
import '../style.css';
import Popup from "reactjs-popup";
import {DialogBox} from "../When Submited Components/DialogBox";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import example_pack from './example_pack.json'

class CreateBot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPack: true,
            isBot: false,
            client_name: '',
            primary_name: '',
            contact_email: '',
            bots: example_pack.bots,
            all_bots: [],
            pack_name: example_pack.name,
            create_pack: example_pack,
        };
        this.handleBotChange = this.handleBotChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePackNameChange = this.handlePackNameChange.bind(this);
    }

    fetchAllBots = () => {
        //Fetches all of the available bots
        fetch(process.env.REACT_APP_API_V1_URL+"bots/list").then(response => {
                return response.json().then(data => {
                    //console.log(data);
                    this.setState({
                        all_bots: data.bots
                    });
                });
            }
        );
    };

    handleBotChange(event) {
        this.setState({
            bots: event.target.value
        })
        const clone = JSON.parse(JSON.stringify(this.state.create_pack));
        clone.bots = event.target.value;
        this.setState({create_pack: clone})
    };

    handlePackNameChange(event){
        this.setState({
            pack_name: event.target.value
        })

        const clone = JSON.parse(JSON.stringify(this.state.create_pack));
        clone.name = event.target.value;
        this.setState({create_pack: clone})
    }

    componentDidMount() {
        this.fetchAllBots();
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        })
    }

    submitHandler = e => {
        e.preventDefault()
        console.log(this.state.create_pack)
    }

    render() {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        return (
            <div className={"create-div"}>
                <Navigation/>
                <form onSubmit={this.submitHandler} className={"create-div-form"}>
                    <div>
                        <h2>Pack Information</h2>
                        <label id={"create-bots-label"}>
                            Primary Name:
                            <input
                                type="text"
                                value={this.state.primary_name}
                                name="primary_name"
                                placeholder="Primary Name"
                                onChange={this.handleChange}/>
                        </label>
                        <label id={"create-bots-label"}>
                            Client Name:
                            <input
                                type="text"
                                value={this.state.client_name}
                                name="client_name"
                                placeholder="Client Name"
                                onChange={this.handleChange}/>
                        </label>
                        <label id={"create-bots-label"}>
                            Contact Email:
                            <input
                                type="text"
                                value={this.state.contact_email}
                                name="contact_email"
                                placeholder="Contact Email"
                                onChange={this.handleChange}/>
                        </label>
                        <label id={"create-bots-label"}>
                            Pack Name:
                            <input
                                type="text"
                                value={this.state.pack_name}
                                name="pack_name"
                                placeholder="Pack Name"
                                onChange={this.handlePackNameChange}/>
                        </label>
                        <label id={"create-bots-label"}>
                            Select bot(s):
                            <br/>
                            <FormControl className={"formControl"}>
                                <Select
                                    labelId="demo-mutiple-chip-label"
                                    id="demo-mutiple-chip"
                                    multiple
                                    value={this.state.bots}
                                    onChange={this.handleBotChange}
                                    renderValue={(selected) => (
                                        <div className={"chips"}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} className={"chip"}/>
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >

                                    {this.state.all_bots.map((bot, index) => (
                                        <MenuItem key={index} value={bot}>
                                            {bot}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </label>
                    </div>
                    <Popup modal trigger={<button type="submit" id={"create-form-submit"}>Submit</button>}>
                        {close => <DialogBox bot={this.state.isBot} pack={this.state.isPack}
                                             data={this.state.create_pack}/>}
                    </Popup>
                </form>
            </div>
        )
    }
}

export default CreateBot;
