import React, {Component} from 'react';
import Navigation from './Navigation'
import '../style.css';
import DCBom_Icon from '../Images&Icons/DCBom_Icon.png'
import RD_Icon from '../Images&Icons/RD_Icon.png'
import Popup from "reactjs-popup";
import {ModalMenu} from "./ModalMenu";

class LandingPage extends Component {
    constructor() {
        super(undefined);
        this.state = {
            packs: [],
            bots: [],
            bot_map: []
        };
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_V1_URL + "packs/list").then(response =>
            response.json().then(data => {
                this.setState({
                    packs: data.packs,
                });
            })
        );

        fetch(process.env.REACT_APP_API_V1_URL + "packs/get").then(response =>
            response.json().then(data => {
                this.setState({
                    bots: data.packs,
                });
            })
        );

        fetch(process.env.REACT_APP_API_V1_URL + "name/list").then(response =>
        response.json().then(data => {
            this.setState({
                bot_map: data['pairs']

            })
        }))
    }


    render() {
        return (
            <div className="lp-div">
                <Navigation/>
                <h1 id="lp-header">Select a Tool</h1>
                <div id="lp-div-figure">
                    <Popup modal trigger={
                        <figure id="research">
                            <img src={DCBom_Icon} alt="DCBom"/>
                            <figcaption>DCBOM</figcaption>
                        </figure>
                    }><ModalMenu packs={this.state.packs} pack_bots={this.state.bots} bot_map={this.state.bot_map}/>
                    </Popup>

                    <figure id="research" >
                        <img src={RD_Icon} alt="Research & Development"/>
                        <figcaption>Research & Development</figcaption>
                    </figure>
                </div>
            </div>
        )
    }
}

export default LandingPage;