import React from "react";
import {Link} from "react-router-dom";
import animationData from './lf30_editor_2eNbOu.json';
import {DivvyCloudAuthentication, DownloadJSON, NothingSelected, SubmitJSON} from './RadioButtonOptions';
import Lottie from 'react-lottie';
import './dialogbox_style.css';
import axios from "axios";
import username from "./username.json";
import password from "./password.json";
import JSZip from "jszip";


export class DialogBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isUsernameAuth: false,
            isPasswordAuth: false,
            isIncorrect: false,
            buttonDisabled: true,
            lottie: false,
            bot: this.props.bot,
            pack: this.props.pack,
            data: this.props.data,
            client: this.props.client,
            id: this.props.id,
            isDefault: true,
            isUploadBotSelected: false,
            isUploadPackSelected: false,
            isDownloadBotPackSelected: false,
            isNoneSelected: false,
            bot_data: [],
        };
        this.handleChange = this.handleChange.bind(this);
        this.divvyCloudAuthentication = this.divvyCloudAuthentication.bind(this);
        this.changeIsIncorrect = this.changeIsIncorrect.bind(this);
    }

    triggerUploadBotsSelected = () => {
        this.setState(() => ({
            ...this.state,
            isDefault: false,
            isUploadBotSelected: true,
            isUploadPackSelected: false,
            isDownloadBotPackSelected: false,
            isNoneSelected: false,
        }))
    }

    triggerUploadPacksSelected = () => {
        this.setState(() => ({
            ...this.state,
            isDefault: false,
            isUploadBotSelected: false,
            isUploadPackSelected: true,
            isDownloadBotPackSelected: false,
            isNoneSelected: false,
        }))
    }

    triggerDownloadSelected = () => {
        this.setState(() => ({
            ...this.state,
            isDefault: false,
            isUploadBotSelected: false,
            isUploadPackSelected: false,
            isDownloadBotPackSelected: true,
            isNoneSelected: false,
        }))
    }

    triggerNoneSelected = () => {
        this.setState(() => ({
            ...this.state,
            isDefault: false,
            isUploadBotSelected: false,
            isUploadPackSelected: false,
            isDownloadBotPackSelected: false,
            isNoneSelected: true,
        }))
    }

    submitJSONHandler = e => {
        e.preventDefault();
        this.changeLottieButton()
        const r_data = {
            client: this.state.client,
            id: this.state.id,
            data: this.state.data
        }
        const options = {'Content-Type': 'application/json'}
        axios.post('https://us-central1-nephotools-dev.cloudfunctions.net/update-bot', r_data, options)
            .then(response => {
                console.log(response)
                console.log(response.headers)
            })
            .catch(error => {
                console.log(error)
            })
    };

    componentDidMount() {
        if (this.state.pack) {
            console.log(process.env.REACT_APP_API_V1_URL+" API URL");
            const {data} = this.state;
            for (const bot of data.bots) {
                fetch(process.env.REACT_APP_API_V1_URL + "bots/get?id=" + bot).then(response =>
                    response.json().then(data => {
                        this.setState({bot_data: [...this.state.bot_data, data.bots[0]]}) //simple value
                    })
                );
            }
        }
    }


    downloadFiles = async () => {
        const {data} = this.state;

        if (this.state.bot) {
            const fileName = this.state.data.name;
            const json = JSON.stringify(data);
            const blob = new Blob([json], {type: 'application/json'});
            const href = await URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        if (this.state.pack) {
            const zip = new JSZip();
            const pack_name = data.name;
            const packs = zip.folder(pack_name); //data.name shows the name of the pack
            let count = 0;

            for (const bot of data.bots) {
                const filename = bot + ".json";
                const json = JSON.stringify(this.state.bot_data[count]);
                const blob = new Blob([json], {type: 'application/json'});
                packs.file(filename, blob);
                count++;
            }

            const FileSaver = require('file-saver');
            zip.generateAsync({type: "blob"})
                .then(function (content) {
                    FileSaver.saveAs(content, "pack.zip");
                });
        }
        this.changeLottieButton()
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        })
    }

    divvyCloudAuthentication(event) {
        event.preventDefault();
        username.username = this.state.username
        password.password = this.state.password
        axios.post('https://divvy.nephosec.com/v3/auth/initiate', username)
            .then(usernameResponse => {
                if (usernameResponse.status === 200) {
                    this.setState({
                        isUsernameAuth: true
                    });
                }

                password.token = usernameResponse.data.token;
                axios.post('https://divvy.nephosec.com/v3/auth/provider/local', password)
                    .then(passwordResponse => {
                        if (passwordResponse.status === 200) {
                            this.setState({
                                isPasswordAuth: true
                            });
                        }

                        this.divvyCloudAuthorization();
                    })
                    .catch(error => {
                        console.log(error)
                        this.changeIsIncorrect()
                    })
            })
            .catch(error => {
                console.log(error)
                this.changeIsIncorrect()
            })
    }

    changeIsIncorrect() {
        this.setState({
            isIncorrect: true,
        })
    }

    divvyCloudAuthorization = () => {
        if (this.state.isUsernameAuth && this.state.isPasswordAuth) {
            this.changeLottieButton()
            const token = Buffer.from(`${this.state.username}:${this.state.password}`, 'utf8').toString('base64')
            const headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Basic ${token}`
            };
            const r_data = {}
            if(this.state.isPack)
                r_data['pack'] = this.state.pack
                r_data['data'] = this.state.data
            const r_data_string = r_data
            const url = "https://us-central1-nephotools-dev.cloudfunctions.net/upload-divvy-bot"
            axios.post(url, r_data_string, {headers: headers})
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    submitConfigDC = e => {
        this.submitJSONHandler(e);
        this.divvyCloudAuthentication(e);
    }

    submitConfigDL = e => {
        this.submitJSONHandler(e);
        this.downloadFiles();
    }

    changeLottieButton() {
        this.setState({
            lottie: true,
            buttonDisabled: false,
        })
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div className="db-overall">
                <header className="db-header">Successfully edited!</header>
                {this.state.lottie === false && <main className={"db-main"}>
                    <div className={"split dialog-leftSide"}>
                        {this.state.bot && <label className={"db-ls-label"}>
                            <input type={"radio"} checked={this.state.isUploadBotSelected} name={"options"}
                                   onChange={this.triggerUploadBotsSelected}/>
                            Upload bot to DivvyCloud
                        </label>}
                        {this.state.pack && <label className={"db-ls-label"}>
                            <input type={"radio"} checked={this.state.isUploadPackSelected} name={"options"}
                                   onChange={this.triggerUploadPacksSelected}/>
                            Upload pack to DivvyCloud
                        </label>}
                        <label className={"db-ls-label"}>
                            <input type={"radio"} checked={this.state.isDownloadBotPackSelected} name={"options"}
                                   onChange={this.triggerDownloadSelected}/>
                            Download JSON
                        </label>
                        <label className={"db-ls-label"}>
                            <input type={"radio"} checked={this.state.isNoneSelected} name={"options"}
                                   onChange={this.triggerNoneSelected}/>
                            Upload to GCP Bucket
                        </label>
                    </div>
                    <div className={"split dialog-rightSide"}>
                        {this.state.isDefault && <NothingSelected/>}
                        {this.state.isUploadBotSelected &&
                        <DivvyCloudAuthentication username={this.state.username}
                                                  password={this.state.password}
                                                  handleChange={this.handleChange}
                                                  isIncorrect={this.state.isIncorrect}
                                                  divvyCloudAuthorization={this.submitConfigDC}/>}
                        {this.state.isUploadPackSelected &&
                        <DivvyCloudAuthentication username={this.state.username}
                                                  password={this.state.password}
                                                  handleChange={this.handleChange}
                                                  isIncorrect={this.state.isIncorrect}
                                                  divvyCloudAuthorization={this.submitConfigDC}/>}
                        {this.state.isDownloadBotPackSelected &&
                        <DownloadJSON downloadFile={this.submitConfigDL} data={this.state.data}/>}
                        {this.state.isNoneSelected &&
                        <SubmitJSON submitJSONHandler={this.submitJSONHandler} data={this.state.data}/>}
                    </div>
                </main>}
                {this.state.lottie && <main className={"db-main-lottie"}>
                    <Lottie options={defaultOptions} height={400} width={400}/>
                </main>}
                <footer className="db-footer">
                    <Link to={"/"}>
                        <button disabled={this.state.buttonDisabled}>Return Home</button>
                    </Link>
                </footer>
            </div>
        )
    }
}