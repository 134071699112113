import React from 'react';
import Popup from "reactjs-popup";
import JSONPretty from 'react-json-pretty';
import Select from 'react-select';
import {CSSTransition} from "react-transition-group";
import insertTextAtCursor from 'insert-text-at-cursor';
import Navigation from "../Home Components/Navigation";
import {DialogBox} from "../When Submited Components/DialogBox";

import '../style.css';


class EditExistingBot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client_name: '',
            name: '',
            client_email: '',
            bot_name_url: this.props.match.params.bot,
            bot_data: {},
            map_keys: [],
            attr_map: [],
            activeMenu: 'main',
            categories: [
                {
                    name: "Security",
                    value: "Security",
                },
                {
                    name: "Optimization",
                    value: "Optimization",
                },
                {
                    name: "Best Practices",
                    value: "Best Practices",
                },
                {
                    name: "Curation",
                    value: "Curation",
                },
                {
                    name: "Miscellaneous",
                    value: "Miscellaneous",
                }
            ],
            category: '',
            severities: [
                {
                    name: "Low",
                    value: "low",
                },
                {
                    name: "Medium",
                    value: "medium",
                },
                {
                    name: "High",
                    value: "high",
                },
            ],
            severity: '',
            message_body: '',
            show_message_body: false,
            description: '',
        };
        this.handleMapKeyChange = this.handleMapKeyChange.bind(this);
        this.handleBotChanges = this.handleBotChanges.bind(this);
    }

    handleBotChanges(event) {
        const {name, value} = event.target;
        const clone = JSON.parse(JSON.stringify(this.state.bot_data));

        switch (name) {
            case "message_body":
                clone["instructions"].actions[1].config.message_body = event.target.value;
                break;
            default:
                if (name !== "client_name" && name !== "client_email")
                    clone[name] = value;
                break;
        }
        this.setState({
            [name]: value,
            bot_data: clone
        })
    }


    handleMapKeyChange(optionValue) {
        this.setState({
            activeMenu: 'json_data',
        })
        this.fetchAttrMap(optionValue);
    }

    componentDidMount() {
        this.fetchBot();
        this.fetchMapKeys()
    }

    fetchBot = () => {
        const bot = this.state.bot_name_url;
        const client = this.state.client_name;
        console.log(client)
        let fetch_data = ""
        if (client === '') {
            fetch_data = process.env.REACT_APP_API_V1_URL + "bots/get?id=" + bot
            console.log(fetch_data)
        }
        else {
            fetch_data = process.env.REACT_APP_API_V1_URL + "bots/get?id=" + client + '/' + bot
            console.log(fetch_data)
        }
        console.log(fetch_data)
        fetch(fetch_data).then(response => {
                return response.json().then(data => {
                    if (data.bots[0]["instructions"].actions.hasOwnProperty(1)) {
                        this.setState({
                            bot_data: data.bots[0],
                            message_body: data.bots[0]["instructions"].actions[1].config.message_body,
                            show_message_body: true,
                            name: data.bots[0].name,
                            description: data.bots[0].description,
                        });
                    } else {
                        this.setState({
                            bot_data: data.bots[0],
                            /*show_message_body: false,*/
                            name: data.bots[0].name,
                            description: data.bots[0].description,
                        });
                    }
                });
            }
        );
    };

    fetchMapKeys = () => {
        fetch(process.env.REACT_APP_API_V1_URL+ 'map/list').then(response => {
            return response.json().then(data => {
                this.setState({
                    map_keys: data.keys
                })
            })
        })
    };

    fetchAttrMap = (optionValue) => {
        fetch(process.env.REACT_APP_API_V1_URL + "map/get?id=" + optionValue).then(response => {
            return response.json().then(data => {
                this.setState({
                    attr_map: data.attributes
                })
            })
        })
    };

    render() {
        const {categories, severities} = this.state;
        let category = this.state.bot_data.category;
        let severity = this.state.bot_data.severity;

        return (
            <div className={"edit-div"}>
                <Navigation/>
                <div className="edit-leftside">
                    <form onSubmit={e => e.preventDefault()}>
                        <h1 className={"edit-form-header"}>DCBOM</h1>
                        <div className={"edit-leftdiv-formdiv"}> {/*Bot Editor Section*/}
                            <h2>Bot Editor</h2>
                            <label className={"edit-form-label"}>
                                Name:
                                <input
                                    type={"text"}
                                    value={this.state.name}
                                    name={"name"}
                                    placeholder={"Bot Name"}
                                    onChange={this.handleBotChanges}/>
                            </label>
                            <label className={"edit-form-label"}>
                                Category:
                                <select onChange={this.handleBotChanges} value={category} name={"category"}>
                                    {categories.map((category, index) => (
                                        <option key={index} value={category.value}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label className={"edit-form-label"}>
                                Severity:
                                <select onChange={this.handleBotChanges} value={severity} name={"severity"}>
                                    {severities.map((severity, index) => (
                                        <option key={index} value={severity.value}>
                                            {severity.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label className={"edit-form-label"}>
                                Client Name:
                                <input
                                    type="text"
                                    value={this.state.client_name}
                                    name="client_name"
                                    placeholder="Client Name"
                                    onChange={this.handleBotChanges}/>
                            </label>
                            <label className={"edit-form-label"}>
                                Client Email:
                                <input
                                    type="email"
                                    value={this.state.client_email}
                                    name="client_email"
                                    placeholder="client_email@email.com"
                                    onChange={this.handleBotChanges}/>
                            </label>
                            <label className={"edit-form-label-textarea"}>
                                Description:
                                <textarea value={this.state.description}
                                          name={"description"} placeholder={"Description"}
                                          onChange={this.handleBotChanges}/>

                            </label>
                        </div>

                        {this.state.show_message_body && <div> {/*Bot Message Box*/}
                            <h2>Message Box</h2>
                            <div className={"edit-form-div-messageBox"}>
                                <header>
                                    <CSSTransition unmountOnExit in={this.state.activeMenu === "main"} timeout={100}>
                                        <Select classNamePrefix={"main"} className={"main"}
                                                onChange={opt => {
                                                    this.handleMapKeyChange(opt.label)
                                                }}
                                                options={
                                                    this.state.map_keys.map((mapkey, index) => ({
                                                        label: mapkey,
                                                        value: index
                                                    }))}/>
                                    </CSSTransition>

                                    <CSSTransition unmountOnExit in={this.state.activeMenu === "json_data"}
                                                   timeout={100}>
                                        <div className={"json_data"}>
                                            <button className={"json_data-button"}
                                                    onClick={() => {
                                                        this.setState({
                                                            activeMenu: "main"
                                                        })
                                                    }}>

                                            </button>
                                            <Select className={"json_data-select"}
                                                    onChange={opt => {
                                                        insertTextAtCursor(document.getElementById('message_body'), opt.value);
                                                    }}
                                                    options={this.state.attr_map.map((map) => ({
                                                        label: map,
                                                        value: '{{ resource.' + map + ' }}'
                                                    }))}/>
                                        </div>
                                    </CSSTransition>
                                </header>
                                <footer>
                                    <textarea value={this.state.message_body} name={"message_body"} id={"message_body"}
                                              onChange={this.handleBotChanges}/>
                                </footer>
                            </div>
                        </div>}

                        <Popup modal trigger={<button type="submit" id={"edit-form-submit"}>Submit</button>}>
                            {() => <DialogBox bot={true} pack={false} client={this.state.client_name}
                                             id={this.state.bot_name_url} data={this.state.bot_data}/>}
                        </Popup>
                    </form>
                </div>

                <div className="edit-rightside">
                    <pre className="edit-json">
                        <JSONPretty id="json-pretty" data={this.state.bot_data}/>
                    </pre>
                </div>
            </div>
        )
    }
}

export default EditExistingBot
