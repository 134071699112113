import React from 'react'
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import CreateBot from "./DCBom_Create/CreateBot";
import LandingPage from "./Home Components/LandingPage";
import EditExistingPack from './DCBom_Edit/EditExistingPack'
import EditExistingBot from "./DCBom_Edit/EditExistingBot";
require('dotenv').config();


class App extends React.Component {
    render() {
        return (
            <Router>
                <Route exact path={"/"} component={LandingPage}/>
                    <Switch>
                        <Route path="/create" component={CreateBot}/>
                        <Route path="/edit_:pack" component={EditExistingPack}/>
                        <Route path="/edit+:bot" component={EditExistingBot}/>
                    </Switch>
            </Router>
        )
    }
}
export default App;

