import React from 'react';
import './dialogbox_style.css'


export class NothingSelected extends React.Component {
    render() {
        return (
            <p className={"rdo-nothingselected-p"}>Select a radio button</p>
        )
    }
}

export class DivvyCloudAuthentication extends React.Component {
    render() {
        return (
            <div className={"rdo-divvycloudauthentication-div"}>
                <button className={"rdo-divvycloudauthentication-button"}
                        onClick={this.props.divvyCloudAuthorization}>Authenticate
                </button>
                <label className={"rdo-divvycloudauthentication-label"}>
                    Username:
                    <input
                        type="text"
                        value={this.props.username}
                        name="username"
                        placeholder="User Name"
                        className={this.props.isIncorrect ? "rdo-divvycloudauthentication-label-error" : "rdo-divvycloudauthentication-label-input"}
                        onChange={this.props.handleChange}
                    />
                </label>
                <label className={"rdo-divvycloudauthentication-label"}>
                    Password:
                    <input
                        type="password"
                        value={this.props.password}
                        name="password"
                        placeholder="Password"
                        className={this.props.isIncorrect ? "rdo-divvycloudauthentication-label-error" : "rdo-divvycloudauthentication-label-input"}
                        onChange={this.props.handleChange}/>
                </label>
                {this.props.isIncorrect === true &&
                <div className={"rdo-divvycloudauthentication-div-error"}>Username/Password is incorrect</div>}
            </div>
        )
    }
}

export class DownloadJSON extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        }
    }

    render() {
        return (
            <div className={"rdo-downloadjson-div"}>
                <p className={"rdo-downloadjson-p"}>Are you sure you want to download?</p>
                <button className={"rdo-downloadjson-button"} onClick={this.props.downloadFile}>Download</button>
            </div>
        )
    }
}

export class SubmitJSON extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        }
    }

    render() {
        return (
            <div className={"rdo-submitjson-div"}>
                <p className={"rdo-submitjson-p"}>Are you sure you want to submit?</p>
                <button className={"rdo-submitjson-button"} onClick={this.props.submitJSONHandler}>Submit</button>
            </div>
        )
    }
}
