import React, {Component} from 'react'
import NephoTools_Logo from '../Images&Icons/NephoTools_Logo.png'
import NephoTools_Text from '../Images&Icons/NephoTools_Text.png'
import '../style.css'
import {Link} from "react-router-dom";

class Navigation extends Component {
    render () {
        return(
            <div>
                <Link to={"/"}>
                    <nav className={"nav-nav"}>
                        <img src={NephoTools_Logo} alt={'NephoTools Logo'} id="nav-logo"/>
                        <img src={NephoTools_Text} alt={'NephoTools Text'} id="nav-text"/>
                    </nav>
                </Link>
            </div>
        )
    }
}

export default Navigation;