import React from "react";
import './menu.css'
import {MdPlayArrow} from "react-icons/md";
import {Link} from "react-router-dom";

export class ModalMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packs: this.props.packs,
            disableEdit: true,
            disableCreateFrom: true,
            pack: this.props.pack_bots,
            bots: [],
            bot_map: this.props.bot_map,
            bot_names: [],
            showBots: false,
            showPacks: false,
            selected: '',
        }

        this.changePackSelected = this.changePackSelected.bind(this)
        this.changeBotSelected = this.changeBotSelected.bind(this)
    }

    changePackSelected() {
        this.setState(prevState => {
            return {
                disableEdit: false,
                disableCreateFrom: false
            };
        });
    }

    changeBotSelected() {
        this.setState(prevState => {
            return {
                disableEdit: false,
            };
        });
    }

    render() {
        return (
            <div className="menu-overall">
                <header className="menu-header"><strong>Packs and Bots</strong></header>
                <main className={"menu-main"}>
                    <div className={"container-beginning"}>
                        <ul className={"menu-ul-beginning"}>
                            <li>
                                <button onClick={() => {
                                    this.setState({
                                        showPacks: true,
                                    })
                                }} className={"ul-li-beginning-button"}>
                                    <div className={"ul-li-beginning-left"}>
                                        Standard
                                    </div>
                                    <div className={"ul-li-beginning-right"}>
                                        <MdPlayArrow/>
                                    </div>
                                </button>
                            </li>
                            <li>
                                <button className={"ul-li-beginning-button"}>
                                    <div className={"ul-li-beginning-left"}>
                                        Client
                                    </div>
                                    <div className={"ul-li-beginning-right"}>
                                        <MdPlayArrow/>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                    {this.state.showPacks && <div className={"container-packs"}>
                        <ul className={"menu-ul-packs"}>
                            {this.state.packs.map((pack, index) => (
                                <li key={index} className={"ul-li-pack_name"}>
                                    <button className={"ul-li-pack_name-button"}
                                            onDoubleClick={() => {
                                                window.location.assign("/edit_" + pack)
                                            }}
                                            onBlur={() => this.setState({
                                                disableEdit: true,
                                                disableCreateFrom: true
                                            })}
                                            onClick={() => {
                                                this.changePackSelected()
                                                console.log(this.state.bot_map)
                                                const names = []
                                                for(let pack_bot of this.state.pack[0]["bots"]){
                                                    let i = 0
                                                    for(i; i < this.state.bot_map.length; i++){
                                                        if (pack_bot.includes(this.state.bot_map[i]["id"])) {
                                                            names.push(this.state.bot_map[i]["name"])
                                                        }
                                                    }
                                                this.setState({
                                                    bots: this.state.pack[0]["bots"],
                                                    bot_names: names,
                                                    showBots: true,
                                                    selected: '/edit_' + pack,

                                                }
                                                )}
                                            }}>

                                        <div className={"ul-li-pack_name-left"}>
                                            {pack}
                                        </div>
                                        <div className={"ul-li-pack_name-right"}>
                                            <MdPlayArrow/>
                                        </div>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>}
                    {this.state.showBots && <div className={"container-bots"}>
                        <ul className={"menu-ul-bots"}>
                            {this.state.bot_names.map((bot_name, index) => (
                                <li key={index} className={"ul-li-bot"}>
                                    <button className={"ul-li-bot-button"}
                                            onDoubleClick={() => {
                                                let id = ""
                                                for(let bot of this.state.bot_map){
                                                    if(bot_name.includes(bot["name"])){
                                                        id = bot['id']
                                                    }
                                                }
                                                window.location.assign("/edit+" + id)
                                            }}
                                        // onBlur={() => this.setState({
                                        //     disableEdit: true,
                                        // })}
                                            onClick={() => {
                                                this.changeBotSelected()
                                                let id = ""
                                                for(let bot of this.state.bot_map){
                                                    if(bot_name.includes(bot["name"])){
                                                        id = bot['id']
                                                    }
                                                }
                                                this.setState({
                                                    selected: '/edit+' + id,
                                                })
                                            }}>
                                        {bot_name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>}
                </main>
                <footer className="menu-footer">
                    <Link to={"/create"}>
                        <button className={"footer-btn create"}>Create</button>
                    </Link>

                    <Link to={"/create"}>
                        <button className={"footer-btn create_from"} disabled={this.state.disableCreateFrom}>
                            Create From
                        </button>
                    </Link>

                    <Link to={this.state.selected}>
                        <button className={"footer-btn edit"} disabled={this.state.disableEdit}>Edit</button>
                    </Link>
                </footer>
            </div>
        )
    }
}