import React from 'react';
import '../style.css';
import Navigation from "../Home Components/Navigation";
import {DialogBox} from "../When Submited Components/DialogBox";
import Popup from "reactjs-popup";
import JSONPretty from "react-json-pretty";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';


class EditExistingPack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isBot: false,
            isPack: true,
            client_name: "",
            primary_name: "",
            client_email: "",
            bots: [],
            all_bots: [],
            pack: this.props.match.params.pack,
            pack_data: {},
            pack_bots: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleBotChange = this.handleBotChange.bind(this);
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        })
    }

    submitHandler = e => {
        e.preventDefault();
    }

    fetchJSON = () => {
        const pack_name = this.state.pack;
        fetch(process.env.REACT_APP_API_V1_URL + "packs/get?id=" + pack_name).then(response => {
                return response.json().then(data => {
                    console.log(data.packs[0])
                    this.setState({
                        pack_data: data.packs[0],
                        bots: data.packs[0].bots
                    });
                });
            }
        );
    }

    fetchAllBots = () => {
        //Fetches all of the available bots
        fetch(process.env.REACT_APP_API_V1_URL + "bots/list").then(response => {
                return response.json().then(data => {
                    //console.log(data);
                    this.setState({
                        all_bots: data.bots
                    });
                });
            }
        );
    }

    fetchPackBots = () => {
        const pack_name = this.state.pack;
        fetch(process.env.REACT_APP_API_V1_URL + "packs/get/bots?id=" + pack_name).then(response => {
            return response.json().then(data => {
                this.setState( {
                    pack_bots: data.bots
                })
            })
        })
    }

    componentDidMount() {
        this.fetchAllBots();
        this.fetchJSON();
        this.fetchPackBots();
    }

    handleBotChange(event) {
        this.setState({
            bots: event.target.value
        })

        const clone = JSON.parse(JSON.stringify(this.state.pack_data));
        clone.bots = event.target.value;
        this.setState({pack_data: clone})
    };

    render() {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        return (
            <div className={"edit-div"}>
                <Navigation/>
                <div className="edit-leftside">
                    <form onSubmit={this.submitHandler}>
                        <div className={"edit-bots"}>
                            <h2>Edit Pack Configurations</h2>
                            <label className={"edit-form-label"}>
                                Primary Name:
                                <input
                                    type="text"
                                    value={this.state.primary_name}
                                    name="primary_name"
                                    placeholder="Primary Name"
                                    onChange={this.handleChange}/>
                            </label>
                            <label className={"edit-form-label"}>
                                Client Name:
                                <input
                                    type="text"
                                    value={this.state.client_name}
                                    name="client_name"
                                    placeholder="Client Name"
                                    onChange={this.handleChange}/>
                            </label>
                            <label className={"edit-form-label"}>
                                Client Email:
                                <input
                                    type="email"
                                    value={this.state.client_email}
                                    name="client_email"
                                    placeholder="Client Email"
                                    onChange={this.handleChange}/>
                            </label>
                            <FormControl className={"formControl"}>
                                <Select
                                    labelId="demo-mutiple-chip-label"
                                    id="demo-mutiple-chip"
                                    multiple
                                    value={this.state.bots}
                                    onChange={this.handleBotChange}
                                    renderValue={(selected) => (
                                        <div className={"chips"}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} className={"chip"}/>
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >

                                    {this.state.all_bots.map((bot, index) => (
                                        <MenuItem key={index} value={bot}>
                                            {bot}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <Popup modal trigger={<button type="submit" id={"edit-form-submit"}>Submit</button>}>
                            {close => <DialogBox bot={this.state.isBot} pack={this.state.isPack}
                                                 data={this.state.pack_data}/>}
                        </Popup>
                    </form>
                </div>

                <div className="edit-rightside">
                     <pre className="edit-json">
                        <JSONPretty id="json-pretty" data={this.state.pack_data} />
                    </pre>
                </div>
            </div>
        )
    }
}

export default EditExistingPack;